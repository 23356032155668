<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addPageSeosModal' hide-footer>
        <addPageSeos @closeAddPageSeos='toggleAddPageSeos()' @addPageSeosSuccess='addPageSeosSuccess()'></addPageSeos>
      </b-modal>
      <b-modal ref='editPageSeosModal' hide-footer>
        <editPageSeos
          :editingPageSeos='currentPageSeos'
          @closeEditPageSeos='toggleEditPageSeos()'
          @editPageSeosSuccess='editPageSeosSuccess()'
        ></editPageSeos>
      </b-modal>
      <div class='row'>
        <h2>PageSeos</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddPageSeos()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addPageSeos from '../../components/addPageSeos';
import editPageSeos from '../../components/editPageSeos';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'PageUrl',
                field: 'PageUrl',
                sort: 'asc',
              },
              {
                label: 'PageName',
                field: 'PageName',
                sort: 'asc',
              },
              {
                label: 'PageSlug',
                field: 'PageSlug',
                sort: 'asc',
              },
              {
                label: 'PageMetaTitle',
                field: 'PageMetaTitle',
                sort: 'asc',
              },
              {
                label: 'PageMetaDescription',
                field: 'PageMetaDescription',
                sort: 'asc',
              },
              {
                label: 'TrackingCode',
                field: 'TrackingCode',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addPageSeosOpen: false,
      editPageSeosOpen: false,
      currentPageSeos: {}
    };
  },
  components: {
    addPageSeos,
    editPageSeos,
    Datatable,
  },
  created: function() {
    this.getPageSeos();
    this.addPageSeosOpen = false;
    this.editPageSeosOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getPageSeos() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getPageSeos', payload);
    },
    editItem(PageSeos) {
      this.toggleEditPageSeos();
      this.currentPageSeos = PageSeos;
    },
    toggleAddPageSeos() {
      if(this.addPageSeosOpen)
      {
        this.$refs.addPageSeosModal.hide()
      }
      else{
        this.$refs.addPageSeosModal.show()
      }
      this.addPageSeosOpen = !this.addPageSeosOpen;
    },
    addPageSeosSuccess() {
      this.toggleAddPageSeos();
      miniToastr['success']('PageSeos Added', 'Success', 1000, null);
      this.getPageSeos();
    },
    toggleEditPageSeos() {
      if(this.editPageSeosOpen)
      {
        this.$refs.editPageSeosModal.hide()
      }
      else{
        this.$refs.editPageSeosModal.show()
      }
      this.editPageSeosOpen = !this.editPageSeosOpen;
    },
    editPageSeosSuccess() {
      this.toggleEditPageSeos();
      miniToastr['success']('PageSeos Edited', 'Success', 1000, null);
      this.getPageSeos();
    }
  }
};
</script>
<style scoped>
.PageSeosThumbnail {
  height: 50px;
}
</style>
